import Nav from '../components/Nav'
import Home from '../sections/home'

export default class Framework {
	constructor() {
		this.init()
	}
	
	init() {
		new Home
		new Nav

	}
}