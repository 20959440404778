import bind from 'es6-class-bind-all'
import event from 'dom-event'
import select from 'dom-select'
import { gsap } from "gsap"
import configQ from '../utils/configQ'
import config from '../config'

class Default {
	constructor() {
		this.init()
		bind(this)
	}

	init() {

		configQ(config.width)
		this.mounted && this.mounted()
		config.isDevice && FastClick.attach(config.body)
		this.addEventsDef()
		console.log(config.isTwo)
		setTimeout(() => {
			config.isTwo && this.setHeightMobile()
		},100)

	}

	addEventsDef() {

		event['on'](window,'resize', () => {
			this.onResize(this) })

	}

	setHeightMobile() {

		let vh = window.innerHeight
		select('html').style.setProperty('--vh', `${vh}px`)

	}

	onResize(def) {

		configQ(window.innerWidth)
		config.isTwo && def.setHeightMobile()

	}
	
}

export default Default