import domready from 'domready'
import Framework from './script/framework'
import device from 'current-device'
import config from './script/config'



// General Config Global
config.device = device
config.isTouch = device.mobile() || device.tablet()
config.isMobile = device.mobile()

domready(() => {

	new Framework()
	
})