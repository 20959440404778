import config from "../config"

export default (w) => {

    console.log(w)
    w < 800 || document.querySelector('html').classList.contains('mobile')
        ? config.isSmall = true
        : config.isSmall = false

    w < 800
        ? config.isOne = true
        : config.isOne = false

    w < 1000 || document.querySelector('html').classList.contains('tablet')
        ? config.isTwo = true
        : config.isTwo = false

    document.querySelector('html').classList.contains('mobile') || document.querySelector('html').classList.contains('tablet')
        ? config.isTouch = true
        : config.isTouch = false

    return config.isTwo

}