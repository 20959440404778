import select from 'dom-select'
import { gsap } from "gsap"
import event from 'dom-event'
import config from '../config'
import bind from 'es6-class-bind-all'

class Nav {

	constructor() {
		bind(this)
		setTimeout(() => {
			this.cache()
			this.init()
		},400)

	}

	cache() {

        this.navigation = select('.nav_mob')
        this.burger = select('.burger')
        this.itemsNav = [...this.navigation.querySelectorAll('.nav_item')]

	}

	init() {

		this.addEventsNav('on')

	}

	addEventsNav(type){

        config.isTwo && event[type](this.burger, 'click', this.toggleNavigation)

        this.itemsNav.forEach((item) => {
            config.isTwo && event[type](item, 'click', this.closeNavigation)
        })

	}

    closeNavigation() {

        const nav = select(".layout_nav")
        const bNav = select('.b-container')
        this.navigation.classList.remove('open')
        select('body').classList.remove('nav_mob_open')
        bNav.classList.remove('open')
        const tl = gsap.timeline()
        .staggerTo(this.itemsNav, 0, {
            opacity: 0,
            rotateY: 100,
            ease: 'back.inOut(1)',
            x: 10,
        }, 0, '+=0.1')

    }

    openNavigation() {

        const nav = select(".layout_nav")
        const bNav = select('.b-container')
        const items = [...document.querySelectorAll('.b-nav li')]
        this.burger.classList.add('open')
        select('body').classList.add('nav_mob_open')
        bNav.classList.add('open')

        const tl = gsap.timeline()
        .staggerTo(this.itemsNav, 0.2, {
            opacity: 1,
            rotateY: 0,
            ease: "back.inOut(2)",
            x: 0,
        }, 0.1, '+=0.2')

        this.navigation.classList.add('open')

    }

    toggleNavigation() {

        const open = this.navigation.classList.contains('open')

        if( open ) {

            this.closeNavigation()

        } else {

            this.openNavigation()

        }

    }
}

export default Nav