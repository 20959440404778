import Default from './default'
import es6ClassBindAll from 'es6-class-bind-all'
import Flickity from 'flickity'
import $ from 'jquery'
import 'jquery-ui/ui/core';
import 'jquery-ui/ui/widgets/draggable';
import event from 'dom-event'
import select from 'dom-select'
import { gsap } from "gsap"
import config from '../config';
import ImagePreloader from "image-preloader"
import PromiseDelay from "promise-ext-delay"


class Home extends Default {
    constructor() {
        super()
        es6ClassBindAll(this)
    }

    mounted() {

        this.initClass()
        this.cache()

    }

    cache() {

        this.oldScroll = select('body').getBoundingClientRect().top

    }

    initClass() {

        this.hidePreloader()
        this.addEvents('on')

        if (!config.isTwo) {

            this.initDraggable()

        }

    }

    hidePreloader() {

        PromiseDelay()
        const preloader = new ImagePreloader
        const images = [...document.querySelectorAll('img')]
        document.body.style.overflow = 'hidden'

        this.tl = gsap.timeline()
        this.tl.to(select('.preload_content'), {
            duration: 0.4,
            opacity: 1,
            ease: "back.inOut(2)"
        })

        preloader.preload(images).delay(100).then((result) => {

            this.initCarousels()
            if (!config.isTwo) {

                this.initItemNavPositions()
                setTimeout(() => {
                    this.initMonique(this)
                }, 1500)

            }

            this.tl.to(select('.preloader'), {
                duration: 0.4,
                opacity: 0,
                ease: "back.inOut(1)"
            }, "+=1.5")
            this.tl.call(() => {
                select('.preloader').remove()
                document.body.style.overflow = 'auto'
            })

            result.forEach((image) => {
                if (image.status) {
                    image.value.classList.add('img-rounded', 'loaded')
                }
            })
        })
    }

    cache() {

        this.oldScroll = select('body').getBoundingClientRect().top

    }

    addEvents(type) {

        !config.isTwo && event['on'](window, 'scroll', () => {
            this.initMonique(this)
        })

        let navItems = document.querySelectorAll('.button_nav')
        navItems.forEach((button) => {
            event['on'](button, 'click', this.scrollToPart)
        })

        if (config.isTwo) {

            const categoryProducts = document.querySelectorAll('.title_category')

            categoryProducts.forEach((cat) => {

                const item = cat.closest('.products_list_item')
                event['on'](cat, 'click', (e) => {
                    this.toggleCat(this, item)
                })

            })

            event['on'](window, 'scroll', () => {
                this.initMoniqueMobile(this)
            })

        }

    }

    initItemNavPositions() {

        console.log('init Posit')
        const items = document.querySelectorAll('.nav_item')
        let compt = 0

        items.forEach((item) => {

            const itemWidth = item.getBoundingClientRect().width

            item.dataset.width = itemWidth
            item.dataset.position = compt

            compt = compt + itemWidth

        })

    }

    initCarousels() {

        const carousels = document.querySelectorAll('.main-carousel')

        carousels.forEach((carousel) => {

            const elems = [...carousel.querySelectorAll('.carousel-cell img')]
            let widthTotal = 0

            elems.forEach((elm) => {

                const height = elm.offsetWidth
                widthTotal = widthTotal + height

            })

            let widthEnought
            widthTotal > window.innerWidth
                ? widthEnought = true
                : widthEnought = false

            if (widthEnought) {

                let requestId;

                const mainTicker = new Flickity(carousel, {
                    // options
                    accessibility: true,
                    resize: true,
                    wrapAround: true,
                    prevNextButtons: false,
                    pageDots: false,
                    percentPosition: true,
                    setGallerySize: true,
                    draggable: true,
                    freeScroll: true,

                })

                mainTicker.x = 0;

                widthEnought && play()

                function play() {

                    mainTicker.x = mainTicker.x - 1.5;
                    mainTicker.settle(mainTicker.x);
                    requestId = window.requestAnimationFrame(play);

                }

                // Main function to cancel the animation.
                function pause() {
                    if (requestId) {
                        window.cancelAnimationFrame(requestId)
                        requestId = undefined;
                    }
                }

                // Pause on mouse over / focusin
                carousel.addEventListener('mouseover', pause, false);
                // carousel.addEventListener('focusin', pause, false);

                // Unpause on mouse out / focusout
                carousel.addEventListener('mouseout', play, false);
                // carousel.addEventListener('focusout', play, false);

            } else {

                const flkty = new Flickity(carousel, {
                    // options
                    cellAlign: 'left',
                    wrapAround: false,
                    prevNextButtons: false,
                    pageDots: false,
                    freeScroll: false,
                    draggable: false,
                    wrapAround: false,

                })
            }
        })
    }

    initDraggable() {

        $("#cartoon_gif").draggable()

    }

    initMonique(home) {
        const animItems = [...document.querySelectorAll('.page_part')],
            height = window.innerHeight,
            scroll = select('body').getBoundingClientRect().top

        animItems.forEach((item) => {

            const topPart = item.getBoundingClientRect().top,
                bottomPart = item.getBoundingClientRect().bottom

            if (bottomPart > 0 && topPart < height) {

                if (!item.classList.contains('appeared')) {

                    item.classList.add('appeared')

                }

            }

            if (item == animItems[4]) console.log(topPart)

            // SI JE SCROLL DOWN
            if (scroll <= this.oldScroll) {

                // item.classList.contains("workshops") && console.log(topPart, bottomPart)

                // ENTRE PAR LE BAS 
                if (topPart < 150 && bottomPart > 150) {

                    // console.log('1', item)
                    if (!item.classList.contains("active")) {

                        const oldActive = select('.active')
                        oldActive && oldActive.classList.remove('active')
                        home.isActiving(item)

                    }

                }

                // OU SORT PAR LE HAUT
                if (bottomPart < 0 && topPart > 0) {


                    if (item.classList.contains('active')) {

                        console.log('2')
                        item.classList.remove('active')
                        !animItems[key + 1].classList.contains('active') && this.isActiving(animItems[key + 1])

                    }
                }

                // Si j'arrive au bout de la page identifier le footer qui ne peux pas arriver a 150 
                if (item.classList.contains('workshops') && topPart < height - select('.footer').offsetHeight + select('.workshops').offsetHeight - 10) {

                    if (!item.classList.contains('active')) {

                        console.log('3')
                        const oldActive = select('.active')
                        oldActive && oldActive.classList.remove('active')
                        home.isActiving(select('.workshops'))

                    }
                }

            } // SI JE SCROLL UP
            else {

                // ENTRE PAR LE HAUT
                if (topPart < 300 && topPart > 0) {

                    if (!item.classList.contains('active')) {

                        console.log('4')
                        const oldActive = select('.active')
                        oldActive && oldActive.classList.remove('active')
                        home.isActiving(item)

                    }
                }

                if (bottomPart > height && topPart < 0) {

                    if (!item.classList.contains('active')) {

                        console.log('6')
                        const oldActive = select('.active')
                        oldActive && oldActive.classList.remove('active')
                        home.isActiving(item)

                    }
                }

            }

        })

        this.oldScroll = scroll

    }

    initMoniqueMobile(home) {

        const scroll = select('body').getBoundingClientRect().top

        if (scroll < this.oldScroll) {

            !select('header').classList.contains('hide') && home.hideHeader()

        } else {

            select('header').classList.contains('hide') && home.showHeader()

        }

        this.oldScroll = scroll

    }

    isActiving(part) {

        const anchor = part.dataset.anchor
        // console.log(part, anchor)
        let itemNavSelected = select(`.nav .nav_item[data-id="${anchor}"]`)
        itemNavSelected ? itemNavSelected = itemNavSelected : itemNavSelected = select(`.button_contact`)

        // console.log(itemNavSelected)

        const itemNavWidth = itemNavSelected.dataset.width,
            itemNavPosition = itemNavSelected.dataset.position

        if (!itemNavPosition) {
            part.classList.add('active')
            this.initMonique(this)
            return
        } else {

            part.classList.add('active')
        }

        if (!part.classList.contains('footer')) {

            console.log('suite')
            const selector = select('.selector'),
                oldSelectItem = select('.selected')

            selector.classList.contains('hide') && selector.classList.remove('hide')
            oldSelectItem && oldSelectItem.classList.remove('selected')
            itemNavSelected.classList.add('selected')

            // console.log(anchor, `.nav_item[data-id="${anchor}"]`, itemNavSelected, itemNavWidth, itemNavPosition)
            const tl = gsap.timeline()
                .to(selector, {
                    duration: 0.9,
                    width: itemNavWidth,
                    left: itemNavPosition,
                    ease: "back.inOut(1)"
                })

        } else {

            const contactItem = select(`.button_contact`),
                selector = select('.selector'),
                oldSelectItem = select('.selected')

            oldSelectItem && oldSelectItem.classList.remove('selected')
            contactItem.classList.add('selected')
            selector.classList.add('hide')

        }

    }

    showHeader() {

        select('header').classList.remove('hide')

    }

    hideHeader() {

        select('header').classList.add('hide')

    }

    scrollToPart(e) {

        const itemAnchor = e.target.dataset.id,
            partSelect = $(`.page_part[data-anchor="${itemAnchor}"]`),
            speed = 900

        $('html, body').animate({ scrollTop: partSelect.offset().top }, speed)

    }

    toggleCat(home, item) {

        !item.classList.contains('open')
            ? home.openCat(item)
            : home.closeCat(item)

    }

    openCat(item) {

        const height = item.querySelector('.products_content').offsetHeight,
            wrapper = item.querySelector('.products_wrapper')

        item.classList.add('open')

        const tl = gsap.timeline()
            .to(wrapper, {
                duration: 0.5,
                height: height,
                ease: "back.inOut(2)"

            })

    }

    closeCat(cat) {

        const wrapper = cat.querySelector('.products_wrapper')

        cat.classList.remove('open')

        const tl = gsap.timeline()
            .to(wrapper, {
                duration: 0.3,
                height: 0,
                ease: "back.inOut(2)"

            })

    }

}

export default Home